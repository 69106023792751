<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always" class="topS">
        <el-form ref="searchForm" label-width="80px">
          <el-row :gutter="10">
            <el-col :span="4">
              <div class="beforeDiv"></div>
              <el-button v-if="showadd" @click="addBtn">新增</el-button>
              <el-button v-if="showdelete" @click="batchesDelete"
                >批量删除</el-button
              >
            </el-col>
            <el-col :span="4">
              <el-form-item label="用户名称">
                <el-input
                  placeholder="请输入内容"
                  v-model="searchForm.remark"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="状态" label-width="50px">
                <el-select
                  v-model="searchForm.status"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="有效期" label-width="60px">
                <el-date-picker
                  clearable
                  v-model="searchForm.createTime"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            :data="tabList"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            v-loading="tabloading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="80"></el-table-column>
            <el-table-column prop="remark" label="用户名称"></el-table-column>
            <el-table-column prop="phone" label="联系方式"></el-table-column>
            <el-table-column prop="code" label="授权码"></el-table-column>
            <el-table-column
              prop="invalidDate"
              label="有效期"
            ></el-table-column>

            <el-table-column prop="status" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 0">启用</el-tag>
                <el-tag v-else type="info">停用</el-tag>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="description" label="描述" show-overflow-tooltip></el-table-column> -->
            <!-- 操作按钮列 -->
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <!-- 编辑按钮 -->
                <el-button
                  v-if="showupdate != -1"
                  type="primary"
                  circle
                  icon="el-icon-edit"
                  size="mini"
                  dialogFormVisible
                  @click="handleEdit(scope.row.miniAuthCodeId)"
                ></el-button>
                <!-- 删除按钮 -->
                <el-button
                  v-if="showdelete"
                  size="mini"
                  type="primary"
                  circle
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.miniAuthCodeId)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :current-page="pageNo"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
    <!-- 编辑的弹框 -->
    <el-dialog
      :title="ruleForm.code ? '授权码' : '生成授权码'"
      :visible.sync="diaoShow"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="授权码" v-if="ruleForm.code">
          {{ ruleForm.code }}
          <!-- <el-input placeholder="请输入名字" v-model="ruleForm.name" clearable class="my-input"></el-input> -->
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="remark" label="用户" class="formItem">
              <el-input
                placeholder="请输入名字"
                v-model.trim="ruleForm.remark"
                clearable
                class="my-input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="phone" label="手机号码" class="formItem">
              <el-input
                placeholder="请输入手机号码"
                v-model.trim="ruleForm.phone"
                clearable
                class="my-input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="invalidDate">
              <el-date-picker
                v-model="ruleForm.invalidDate"
                type="date"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                :default-time="defaultTime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-radio
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.value"
                v-model="ruleForm.status"
                border
                >{{ item.label }}</el-radio
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="品牌" prop="brandId">
          <el-select
            v-model="ruleForm.brandId"
            @change="selectOne"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in brandSelectData"
              :key="index"
              :label="item.brandName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="hotels">
          <el-checkbox-group v-model="ruleForm.hotels" @change="changeHotel">
            <el-checkbox
              class="hotelName"
              v-for="(item, index) in hotelSelectData"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
          <!-- <el-select v-model="ruleForm.hotelId" clearable multiple placeholder="请选择">
            <el-option
              v-for="(item,index) in hotelSelectData"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>-->
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="diaoShow = false">取 消</el-button>
        <el-button
          type="primary"
          v-if="showadd || showupdate"
          @click="confim('ruleForm')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getBrandSelect,
  getHotelSelect,
  getCodeList,
  getCodeById,
  insertCode,
  updateCodeById,
  delCodeById,
} from "@/api";
import { getDay, getLongTime } from "@/api/common.js";

export default {
  name: "codeInsert",
  data() {
    /* 手机号自定义校验规则 */
    let Mobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        let mobileReg = /^1(3|4|5|7|8|9)\d{9}$/;
        if (mobileReg.test(value)) {
          callback(); // 校验通过
        } else {
          callback(new Error("请输入正确的手机号")); // 校验不通过
        }
      }
    };
    return {
      height: null,
      loading: false,
      tabloading: false,
      diaoShow: false,
      // 查询的数据
      total: null,
      tabList: [],
      searchForm: {
        createTime: null,
        name: null,
        status: null,
      },
      ruleForm: {
        code: null,
        invalidDate: null, //账号
        miniAuthCodeId: null,
        remark: null,
        status: null,
        phone: null,
        brandId: null,
        hotels: [],
      },
      pageSize: 10,
      pageNo: 1,
      query: {
        remark: null, //账号
        phone: null,
        status: null,
        startInvalidDate: null,
        endInvalidDate: null,
      },
      delList: [],
      brandSelectData: [],
      hotelSelectData: [],
      statusList: [
        {
          label: "启用",
          value: 0,
        },
        {
          label: "停用",
          value: 1,
        },
      ],
      rules: {
        remark: [{ required: true, message: "请输入名字", trigger: "blur" }],
        phone: [{ required: true, validator: Mobile, trigger: "blur" }],
        invalidDate: [{ required: true, message: "请选择", trigger: "change" }],
        status: [{ required: true, message: "请选择", trigger: "change" }],
        brandId: [{ required: true, message: "请选择", trigger: "change" }],
        hotels: [{ required: true, message: "请选择", trigger: "change" }],
      },
      defaultTime: "23:59:59",
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 250;
  },
  computed: {
    // 权限
    showadd: function () {
      return this.userJurisdiction.includes("code:add");
    },
    showupdate: function () {
      return this.userJurisdiction.includes("code:update");
    },
    showdelete() {
      return this.userJurisdiction.includes("code:del");
    },
  },
  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    this.initTime();

    this.initialize();
    // 调用初始化表格数据方法
  },
  methods: {
    initTime() {
      let startTime = getDay(),
        endTime = getDay(1460);
      this.query.startInvalidDate = startTime;
      this.query.endInvalidDate = endTime;
      this.searchForm.createTime = [startTime, endTime];
      this.setQuery();
      this.getlist();
    },
    changeHotel(val) {
      console.log(val);
      console.log(this.ruleForm.hotels);
    },
    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
        }
      });
    },
    selectOne(id) {
      this.ruleForm.hotels = [];
      this.getSlectOneData(id);
    },
    getSlectOneData(id) {
      if (id) {
        getHotelSelect(id).then((res) => {
          if (res.data.data) {
            this.hotelSelectData = res.data.data;
          } else {
            this.$message({
              message: "该品牌下没有门店数据",
              type: "warning",
            });
          }
        });
      } else {
        this.hotelSelectData = [];
      }
    },

    handleSelectionChange(val) {
      this.delList = val;
    },
    addBtn() {
      this.diaoShow = true;
      this.ruleForm = {
        code: null,
        invalidDate: null, //账号
        miniAuthCodeId: null,
        remark: null,
        status: null,
        phone: null,
        brandId: null,
        hotels: [],
      };
      this.ruleForm.brandId = this.brandSelectData[0].id;
      this.selectOne(this.ruleForm.brandId);
    },
    batchesDelete() {
      if (this.delList.length == 0) {
        this.$message.warning("请先选择要删除的数据");
        return;
      }
      let idArr = [];
      this.delList.forEach((v) => {
        idArr.push(v.miniAuthCodeId);
      });
      this.delCode(idArr);
    },

    //查看
    handleEdit(id) {
      getCodeById(id)
        .then((r) => {
          if (r.data.code == "000000") {
            let res = r.data.data;
            this.ruleForm.code = res.code;
            this.ruleForm.invalidDate = res.invalidDate;
            this.ruleForm.miniAuthCodeId = res.miniAuthCodeId;
            this.ruleForm.remark = res.remark;
            this.ruleForm.status = res.status;
            this.ruleForm.phone = res.phone;
            this.ruleForm.brandId = res.hotels[0].brandId;
            let arr = [];
            res.hotels.forEach((v) => {
              arr.push(v.id);
            });
            this.ruleForm.hotels = arr;
            this.$set(this.ruleForm, "hotels", arr);
            // this.selectOne(this.ruleForm.brandId);
            this.getSlectOneData(this.ruleForm.brandId);
            this.diaoShow = true;
          } else {
            this.$message.warning(r.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("获取授权码详情失败");
        });
    },
    // 单个删除
    handleDelete(id) {
      let idArr = [id];
      this.delCode(idArr);
    },
    delCode(idArr) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tabloading = true;
          delCodeById(idArr)
            .then((res) => {
              this.tabloading = false;
              this.getlist();
            })
            .catch((err) => {
              this.tabloading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑用户确认
    confim(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.ruleForm.code) {
            this.updateCode(this.ruleForm);
            // 修改
          } else {
            // 新增
            this.insertCode(this.ruleForm);
          }
        } else {
          return false;
        }
      });
    },
    insertCode(data) {
      insertCode(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("添加成功");
            this.diaoShow = false;
            this.getlist();
          } else {
            this.$message.warning(res.data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("操作失败");
        });
    },
    updateCode(data) {
      updateCodeById(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("修改成功");
            this.diaoShow = false;
            this.getlist();
          } else {
            this.$message.warning(res.data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("操作失败");
        });
    },
    // 查询按钮
    handleSearch() {
      this.pageNo = 1;
      this.setQuery();
      this.getlist();
    },
    setQuery() {
      if (this.searchForm.createTime) {
        this.query.endInvalidDate = this.searchForm.createTime[1];
        this.query.startInvalidDate = this.searchForm.createTime[0];
      } else {
        this.query.endInvalidDate = undefined;
        this.query.startInvalidDate = undefined;
      }
      this.query.remark = this.searchForm.remark; //账号
      this.query.status = this.searchForm.status;
    },
    // 重置按钮
    reset() {
      this.searchForm.remark = null; //账号
      this.searchForm.status = null;
      this.searchForm.createTime = null;
      this.pageNo = 1;
      this.setQuery();
      this.getlist();
    },

    getlist() {
      this.tabloading = true;
      getCodeList(this.pageNo, this.pageSize, this.query)
        .then((res) => {
          this.tabloading = false;
          this.tabList = res.data.data.rows;
          this.total = res.data.data.total;
        })
        .catch((err) => {
          this.tabloading = false;
        });
    },
    //分页
    handleCurrentChange(cpage) {
      this.pageNo = cpage;
      this.getlist(this.ruleForm);
    },
    handleSizeChange(psize) {
      this.pageSize = psize;
      this.getlist(this.ruleForm);
    },
  },
};
</script>
<style lang="scss" scoped>
.formItem {
  height: 40px;
}
.hotelName {
  width: 29%;
  display: inline-block;
}
.my-box {
  .my-input {
    width: 100%;
    // max-width: 215px;
  }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;
    .el-input__inner {
      max-width: 190px;
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
