var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "my-box",
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-card",
            { staticClass: "topS", attrs: { shadow: "always" } },
            [
              _c(
                "el-form",
                { ref: "searchForm", attrs: { "label-width": "80px" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("div", { staticClass: "beforeDiv" }),
                          _vm.showadd
                            ? _c("el-button", { on: { click: _vm.addBtn } }, [
                                _vm._v("新增"),
                              ])
                            : _vm._e(),
                          _vm.showdelete
                            ? _c(
                                "el-button",
                                { on: { click: _vm.batchesDelete } },
                                [_vm._v("批量删除")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "用户名称" } },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入内容",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "remark", $$v)
                                  },
                                  expression: "searchForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态", "label-width": "50px" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.searchForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "status", $$v)
                                    },
                                    expression: "searchForm.status",
                                  },
                                },
                                _vm._l(_vm.statusList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "有效期", "label-width": "60px" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  clearable: "",
                                  type: "daterange",
                                  "range-separator": "-",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.searchForm.createTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "createTime", $$v)
                                  },
                                  expression: "searchForm.createTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "reset-button", attrs: { span: 4 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c("el-button", { on: { click: _vm.reset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-card", { attrs: { shadow: "always" } }, [
            _c(
              "div",
              { staticClass: "my-header-table" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tabloading,
                        expression: "tabloading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tabList,
                      "tooltip-effect": "dark",
                      height: _vm.height,
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "80" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "remark", label: "用户名称" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "phone", label: "联系方式" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "code", label: "授权码" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "invalidDate", label: "有效期" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "status", label: "状态", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status == 0
                                ? _c("el-tag", [_vm._v("启用")])
                                : _c("el-tag", { attrs: { type: "info" } }, [
                                    _vm._v("停用"),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.showupdate != -1
                                ? _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      icon: "el-icon-edit",
                                      size: "mini",
                                      dialogFormVisible: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(
                                          scope.row.miniAuthCodeId
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.showdelete
                                ? _c("el-button", {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      circle: "",
                                      icon: "el-icon-delete",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(
                                          scope.row.miniAuthCodeId
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next, sizes, total, jumper",
                    "page-sizes": [10, 20, 50, 100],
                    "page-size": _vm.pageSize,
                    "current-page": _vm.pageNo,
                    total: _vm.total,
                  },
                  on: {
                    "current-change": _vm.handleCurrentChange,
                    "size-change": _vm.handleSizeChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.ruleForm.code ? "授权码" : "生成授权码",
            visible: _vm.diaoShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.diaoShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _vm.ruleForm.code
                ? _c("el-form-item", { attrs: { label: "授权码" } }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.ruleForm.code) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "formItem",
                          attrs: { prop: "remark", label: "用户" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "my-input",
                            attrs: { placeholder: "请输入名字", clearable: "" },
                            model: {
                              value: _vm.ruleForm.remark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "remark",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "formItem",
                          attrs: { prop: "phone", label: "手机号码" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "my-input",
                            attrs: {
                              placeholder: "请输入手机号码",
                              clearable: "",
                            },
                            model: {
                              value: _vm.ruleForm.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "有效期", prop: "invalidDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy 年 MM 月 dd 日",
                              "value-format": "yyyy-MM-dd",
                              "default-time": _vm.defaultTime,
                            },
                            model: {
                              value: _vm.ruleForm.invalidDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "invalidDate", $$v)
                              },
                              expression: "ruleForm.invalidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态", prop: "status" } },
                        _vm._l(_vm.statusList, function (item, index) {
                          return _c(
                            "el-radio",
                            {
                              key: index,
                              attrs: { label: item.value, border: "" },
                              model: {
                                value: _vm.ruleForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "status", $$v)
                                },
                                expression: "ruleForm.status",
                              },
                            },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brandId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectOne },
                      model: {
                        value: _vm.ruleForm.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "brandId", $$v)
                        },
                        expression: "ruleForm.brandId",
                      },
                    },
                    _vm._l(_vm.brandSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.brandName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店", prop: "hotels" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.changeHotel },
                      model: {
                        value: _vm.ruleForm.hotels,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "hotels", $$v)
                        },
                        expression: "ruleForm.hotels",
                      },
                    },
                    _vm._l(_vm.hotelSelectData, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticClass: "hotelName",
                          attrs: { label: item.id },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.diaoShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.showadd || _vm.showupdate
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.confim("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }